import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const useFetch = (URL, method, safra) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    setLoading(true);

    (async () => {

      const options = {
        method: method || 'GET',
        body: JSON.stringify({ safra }),
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        signal,
      };

      try {

        const response = await fetch(URL, options);

        const dataFetched = await response.json();

        if (response.status !== 200) return navigate('/login');
        
        setData(dataFetched);
        setStatus(response.status);
        setLoading(false);
        setError(null);

      } catch (error) {

        setStatus(null)

        if (!signal.aborted) {
          setLoading(false);
          setError(error.message);
        }

      }
    })()

    return () => {
      abortController.abort();
  };

  }, [URL, method, safra, status, navigate]);

  return { data, loading, error, status };
}

export default useFetch;