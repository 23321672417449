import { useContext } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { AuthContext } from '../../context/auth';

const SaveButton = ({handleAction}) => {
  
  const { isAdmin } = useContext(AuthContext);

  return (
    <Button
      disabled={!isAdmin}
      onClick={() => handleAction()}
      variant='contained'
      color='primary'
      style={{...styles.button, display: isAdmin ? 'flex' : 'none'}}
      endIcon={<EditIcon />}
    >
      Salvar Edição
    </Button>
  );
}

const styles = {
  button: {
    marginTop: 3, 
    width: '20%', 
    minWidth: 'fit-content', 
    textTransform: 'none'
  }
}

export default SaveButton;