import { TextField, Button, Box, Container, CircularProgress } from '@mui/material';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { Copyright } from '../../components';
import useLogin from './useLogin.js'

const Login = () => {

  const { 
    setUser, 
    setErrorUser, 
    setPassword, 
    setErrorPassword, 
    errorUser, 
    errorPassword, 
    handleSubmit, 
    loading, 
    messageError 
  } = useLogin();

  return (

    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <AgricultureIcon color='primary' style={styles.agroIcon} />

        <h1 style={styles.title}> Plataforma <span style={styles.titlemarker}> Rally da Safra </span> </h1>

        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {messageError && <p style={styles.errorMessage}> {messageError} </p>}

          <TextField
            error={errorUser}
            helperText={errorUser && 'Campo obrigatório!'}
            color='primary'
            margin='normal'
            required
            fullWidth
            label='Nome de Usuário'
            name='user'
            autoFocus
            onChange={(e) => {
              setUser(e.target.value);
              if (e.target.value) setErrorUser(false);
              else setErrorUser(true);
            }}
          />

          <TextField
            error={errorPassword}
            helperText={errorPassword && 'Campo obrigatório!'}
            color='primary'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Senha'
            type='password'
            autoComplete='current-password'
            onChange={(e) => {
              setPassword(e.target.value);
              if (e.target.value) setErrorPassword(false);
              else setErrorPassword(true);
            }}
          />

          <Button
            color='primary'
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? <CircularProgress size='1.5rem' color='inherit' /> : 'Entrar'}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>

  );
}

const styles = {
  agroIcon: {
    fontSize: '150px',
  },

  title: {
    textAlign: 'center',
    color: 'rgb(44, 31, 31)',
  },

  titlemarker: {
    textDecoration: 'underline',
    textDecorationColor: '#873e23',
  },
  errorMessage: {
    textAlign: 'center',
    color: '#D32F2F',
  }

};

export default Login;