import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const RadioButton = ({ dataList, name, label }) => {

  return (
    <Grid item sx={{ width: '100%' }}>
      <FormLabel sx={{ display: 'flex', alignSelf: 'center', color: '#1b1f1b', fontSize: '18px', marginTop: '15px', marginRight: '20px' }} component='legend'>{label}</FormLabel>
      <FormControl component='fieldset' >
        <RadioGroup
          name={name}
          row
          defaultValue={dataList[name]}
        >
          
          <FormControlLabel
            value='Alta Infestação'
            control={<Radio onClick={(e) => dataList[e.target.name] = e.target.value} />}
            label='Alta Infestação'
          />

          <FormControlLabel
            value='Possui'
            control={<Radio onClick={(e) => dataList[e.target.name] = e.target.value} />}
            label='Possui'
          />

          <FormControlLabel
            value=''
            control={<Radio onClick={(e) => dataList[e.target.name] = e.target.value} />}
            label='Não Possui'
          />

        </RadioGroup>
      </FormControl>
    </Grid>
  )
}

export default RadioButton;