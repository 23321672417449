import { useContext, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { AuthContext } from '../context/auth';
import useFetch from '../hooks/useFetch';
import { idRoleAdmin } from '../config';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const PrivateRoute = () => {

  const url = `${SERVER_URL}/auth`;
  const { status, loading, data } = useFetch(url, 'POST');

  const { setIsAdmin } = useContext(AuthContext);

  useEffect(() => {
    if (!loading) {
      const role = data.role;
      const isAdmin = role?.id_role === idRoleAdmin;
      setIsAdmin(isAdmin);
    };
 }, [loading, data, setIsAdmin]);

  if (loading) return (
    <Box style={styles.loadingContainer}>
      <CircularProgress size={100} />
    </Box>
  );

  return (
    <>
      {status === 200 ? <Outlet /> : <Navigate to='login' />}
    </>
  )

};

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
  }
}

export default PrivateRoute;