import { useContext, useState } from 'react';

import { AuthContext } from '../../context/auth';

const useLogin = () => {
    
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [errorUser, setErrorUser] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
  
    const { login, loading, messageError } = useContext(AuthContext);
  
    const handleSubmit = async (e) => {
  
      e.preventDefault();
  
      if (!user) setErrorUser(true);
      else setErrorUser(false);
      if (!password) setErrorPassword(true);
      else setErrorUser(false);
  
      if (!user || !password) return;
  
      await login(user, password);
  
    };
    
    return { setUser, setErrorUser, setPassword, setErrorPassword, errorUser, errorPassword, handleSubmit, loading, messageError };
}
 
export default useLogin;