import { useContext } from 'react';
import { AuthContext } from '../../context/auth';

const Fieldset = ({ children }) => {

  const { isAdmin } = useContext(AuthContext);

  return (
    <fieldset disabled={!isAdmin}>
      {children}
    </fieldset>
  );
}

export default Fieldset;