import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Button, Box, Divider, Typography, Grid, TextField, InputAdornment, Modal, CircularProgress } from '@mui/material';

import { TextFieldInput, SelectField, GroupFields, RadioButton, RadioButtonInput, ReturnButton, SaveButton, DeleteButton, Fieldset } from '../../../../components';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const EditBoleta = () => {
  const [open, setOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalErrorOpenDelete, setModalErrorOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [savingData, setSavingData] = useState(false);

  const theme = useTheme();

  const openDeleteModal = () => setOpenModalDelete(true);
  const openModal = () => setOpen(true);

  const primaryColor = theme.palette.primary.main;

  const [searchParams, setSearchParams] = useSearchParams();

  const idBoletaParams = searchParams.get('boletaSojaId');
  const contentParams = searchParams.get('content');

  const navigate = useNavigate();

  const selectedBoleta = JSON.parse(sessionStorage.getItem('editar_boleta_soja'));

  useEffect(() => {
    if (idBoletaParams !== selectedBoleta.id_boleta && contentParams === 'Editar Boleta Soja') navigate('/notfoundpage');

    setLoading(false);
  }, [idBoletaParams, selectedBoleta.id_boleta, contentParams, navigate]);

  const residuoMilho = selectedBoleta['residuo_milho'] ? 'Milho' : '';
  const residuoBraquiaria = selectedBoleta['residuo_braquiaria'] ? 'Braquiaria' : '';
  const residuoMilheto = selectedBoleta['residuo_milheto'] ? 'Milheto' : '';
  const residuoAlgodao = selectedBoleta['residuo_algodao'] ? 'Algodão' : '';
  const residuoGraminea = selectedBoleta['residuo_graminea'] ? 'Gramínea' : '';
  const residuoTrigo = selectedBoleta['residuo_trigo'] ? 'Trigo' : '';
  const residuoAveia = selectedBoleta['residuo_aveia'] ? 'Aveia' : '';
  const residuoOutro = selectedBoleta['residuo_outro_input'] ? selectedBoleta['residuo_outro_input'] : '';

  const residuos = [residuoMilho, residuoBraquiaria, residuoMilheto, residuoAlgodao, residuoGraminea, residuoTrigo, residuoAveia, residuoOutro].filter(Boolean).join(', ');

  const [boletaData] = useState({
    'id_boleta': selectedBoleta['id_boleta'],
    'safra': selectedBoleta['safra'],
    'data': selectedBoleta['data'],
    'equipe': selectedBoleta['equipe'],
    'responsavel': selectedBoleta['responsavel'],
    'uf': selectedBoleta['uf'],
    'municipio': selectedBoleta['municipio'],
    'municipio_geo': selectedBoleta['municipio_geo'],
    'latitude': selectedBoleta['latitude'],
    'longitude': selectedBoleta['longitude'],
    'altitude': selectedBoleta['altitude'],
    'observacao': selectedBoleta['observacao'],
    'amostra_graos': selectedBoleta['amostra_graos'],
    'peso_graos': selectedBoleta['peso_graos'],
    'umidade_graos_1': selectedBoleta['umidade_graos_1'],
    'umidade_graos_2': selectedBoleta['umidade_graos_2'],

    'espacamento_linhas': selectedBoleta['espacamento_linhas'] || selectedBoleta['espacamento_linhas_outro'],
    'linha1': selectedBoleta['linha1'],
    'linha2': selectedBoleta['linha2'],
    'linha3': selectedBoleta['linha3'],
    'linha4': selectedBoleta['linha4'],
    'linha5': selectedBoleta['linha5'],
    'vagem1': selectedBoleta['vagem1'],
    'vagem2': selectedBoleta['vagem2'],
    'vagem3': selectedBoleta['vagem3'],
    'vagem4': selectedBoleta['vagem4'],
    'vagem5': selectedBoleta['vagem5'],
    'grao1': selectedBoleta['grao1'],
    'grao2': selectedBoleta['grao2'],
    'grao3': selectedBoleta['grao3'],
    'grao4': selectedBoleta['grao4'],
    'grao5': selectedBoleta['grao5'],

    'avaliacao_visual': selectedBoleta['avaliacao_visual'],
    'irrigacao': selectedBoleta['irrigacao'],
    'condicao_lavoura': selectedBoleta['condicao_lavoura'],
    'estadio': selectedBoleta['estadio'],
    'umidade_solo': selectedBoleta['umidade_solo'],
    'declividade_terreno': selectedBoleta['declividade_terreno'],
    'textura_solo': selectedBoleta['textura_solo'],
    'dessecacao': selectedBoleta['dessecacao'],
    'semeadura_nivel': selectedBoleta['semeadura_nivel'],
    'sinal_erosao': selectedBoleta['sinal_erosao'],
    'sinal_compactacao': selectedBoleta['sinal_compactacao'],
    'nivel_compactacao': selectedBoleta['nivel_compactacao'] || '',
    'terraceamento': selectedBoleta['terraceamento'],
    'plantio_direto': selectedBoleta['plantio_direto'],
    'cobertura_solo': selectedBoleta['cobertura_solo'],
    'tipos_residuos': residuos || selectedBoleta['tipos_residuos'],

    'lagarta_spodoptera': selectedBoleta['lagarta_spodoptera'],
    'lagarta_falsa_medideira': selectedBoleta['lagarta_falsa_medideira'],
    'lagarta_maca': selectedBoleta['lagarta_maca'],
    'mosca_branca': selectedBoleta['mosca_branca'],
    'percevejo': selectedBoleta['percevejo'],
    'vaquinha': selectedBoleta['vaquinha'],
    'praga_outro_1_nome': selectedBoleta['praga_outro_1_nome'],
    'praga_outro_1_valor': selectedBoleta['praga_outro_1_valor'],
    'praga_outro_2_nome': selectedBoleta['praga_outro_2_nome'],
    'praga_outro_2_valor': selectedBoleta['praga_outro_2_valor'],
    'praga_outro_3_nome': selectedBoleta['praga_outro_3_nome'],
    'praga_outro_3_valor': selectedBoleta['praga_outro_3_valor'],
    'praga_outro_4_nome': selectedBoleta['praga_outro_4_nome'],
    'praga_outro_4_valor': selectedBoleta['praga_outro_4_valor'],

    'algodao_guaxo': selectedBoleta['algodao_guaxo'],
    'amargoso': selectedBoleta['amargoso'],
    'buva': selectedBoleta['buva'],
    'capim_pe_galinha': selectedBoleta['capim_pe_galinha'],
    'carrapicho': selectedBoleta['carrapicho'],
    'corda_viola': selectedBoleta['corda_viola'],
    'fedegoso': selectedBoleta['fedegoso'],
    'leiteiro': selectedBoleta['leiteiro'],
    'milho_guaxo': selectedBoleta['milho_guaxo'],
    'picao_preto': selectedBoleta['picao_preto'],
    'trapoeraba': selectedBoleta['trapoeraba'],
    'daninha_outro_1_nome': selectedBoleta['daninha_outro_1_nome'],
    'daninha_outro_1_valor': selectedBoleta['daninha_outro_1_valor'],

    'antracnose': selectedBoleta['antracnose'],
    'cercospora': selectedBoleta['cercospora'],
    'dfc': selectedBoleta['dfc'],
    'ferrugem': selectedBoleta['ferrugem'],
    'mancha_alvo': selectedBoleta['mancha_alvo'],
    'mofo_branco': selectedBoleta['mofo_branco'],
    'oidio': selectedBoleta['oidio'],
    'doenca_outro_1_nome': selectedBoleta['doenca_outro_1_nome'],
    'doenca_outro_1_valor': selectedBoleta['doenca_outro_1_valor'],
    'doenca_outro_2_nome': selectedBoleta['doenca_outro_2_nome'],
    'doenca_outro_2_valor': selectedBoleta['doenca_outro_2_valor'],
    'doenca_outro_3_nome': selectedBoleta['doenca_outro_3_nome'],
    'doenca_outro_3_valor': selectedBoleta['doenca_outro_3_valor'],
    'doenca_outro_4_nome': selectedBoleta['doenca_outro_4_nome'],
    'doenca_outro_4_valor': selectedBoleta['doenca_outro_4_valor'],

    'produtor_nome': selectedBoleta['produtor_nome'],
    'produtor_tel_1': selectedBoleta['produtor_tel_1'],
    'produtor_tel_2': selectedBoleta['produtor_tel_2'],
    'produtor_email': selectedBoleta['produtor_email'],
    'produtor_fazenda': selectedBoleta['produtor_fazenda'],

    'espacamento_plantas_1': selectedBoleta['espacamento_plantas_1'],
    'espacamento_plantas_2': selectedBoleta['espacamento_plantas_2'],
    'espacamento_plantas_3': selectedBoleta['espacamento_plantas_3'],
    'espacamento_plantas_4': selectedBoleta['espacamento_plantas_4'],
    'espacamento_plantas_5': selectedBoleta['espacamento_plantas_5'],
    'espacamento_plantas_6': selectedBoleta['espacamento_plantas_6'],
    'espacamento_plantas_7': selectedBoleta['espacamento_plantas_7'],
    'espacamento_plantas_8': selectedBoleta['espacamento_plantas_8'],
    'espacamento_plantas_9': selectedBoleta['espacamento_plantas_9'],
    'espacamento_plantas_10': selectedBoleta['espacamento_plantas_10'],
    'espacamento_plantas_11': selectedBoleta['espacamento_plantas_11'],
    'espacamento_plantas_12': selectedBoleta['espacamento_plantas_12'],
    'espacamento_plantas_13': selectedBoleta['espacamento_plantas_13'],
    'espacamento_plantas_14': selectedBoleta['espacamento_plantas_14'],
    'espacamento_plantas_15': selectedBoleta['espacamento_plantas_15'],
    'espacamento_plantas_16': selectedBoleta['espacamento_plantas_16'],
    'espacamento_plantas_17': selectedBoleta['espacamento_plantas_17'],
    'espacamento_plantas_18': selectedBoleta['espacamento_plantas_18'],
    'espacamento_plantas_19': selectedBoleta['espacamento_plantas_19'],
    'espacamento_plantas_20': selectedBoleta['espacamento_plantas_20'],
    'espacamento_plantas_21': selectedBoleta['espacamento_plantas_21'],
    'espacamento_plantas_22': selectedBoleta['espacamento_plantas_22'],
    'espacamento_plantas_23': selectedBoleta['espacamento_plantas_23'],
    'espacamento_plantas_24': selectedBoleta['espacamento_plantas_24'],
    'espacamento_plantas_25': selectedBoleta['espacamento_plantas_25'],
    'espacamento_plantas_26': selectedBoleta['espacamento_plantas_26'],
    'espacamento_plantas_27': selectedBoleta['espacamento_plantas_27'],
    'espacamento_plantas_28': selectedBoleta['espacamento_plantas_28'],
    'espacamento_plantas_29': selectedBoleta['espacamento_plantas_29'],
    'espacamento_plantas_30': selectedBoleta['espacamento_plantas_30'],
    'espacamento_plantas_31': selectedBoleta['espacamento_plantas_31'],
    'espacamento_plantas_32': selectedBoleta['espacamento_plantas_32'],
    'espacamento_plantas_33': selectedBoleta['espacamento_plantas_33'],
    'espacamento_plantas_34': selectedBoleta['espacamento_plantas_34'],
    'espacamento_plantas_35': selectedBoleta['espacamento_plantas_35'],
    'espacamento_plantas_36': selectedBoleta['espacamento_plantas_36'],
    'espacamento_plantas_37': selectedBoleta['espacamento_plantas_37'],
    'espacamento_plantas_38': selectedBoleta['espacamento_plantas_38'],
    'espacamento_plantas_39': selectedBoleta['espacamento_plantas_39'],
    'espacamento_plantas_40': selectedBoleta['espacamento_plantas_40'],
    'espacamento_plantas_41': selectedBoleta['espacamento_plantas_41'],
    'espacamento_plantas_42': selectedBoleta['espacamento_plantas_42'],
    'espacamento_plantas_43': selectedBoleta['espacamento_plantas_43'],
    'espacamento_plantas_44': selectedBoleta['espacamento_plantas_44'],
    'espacamento_plantas_45': selectedBoleta['espacamento_plantas_45'],
    'espacamento_plantas_46': selectedBoleta['espacamento_plantas_46'],
    'espacamento_plantas_47': selectedBoleta['espacamento_plantas_47'],
    'espacamento_plantas_48': selectedBoleta['espacamento_plantas_48'],

  })

  const handleSubmit = async () => {

    setSavingData(true);

    const options = {
      method: 'PUT',
      body: JSON.stringify(boletaData),
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include'
    };

    try {
      const response = await fetch(`${SERVER_URL}/soybean/update`, options);

      if (response.status === 200) navigate('/home');
      else setModalErrorOpen(true);
      setSavingData(false);

    } catch (error) {

      setModalErrorOpen(true);
      setSavingData(false);

    }

    setOpen(false);

  };

  const handleDelete = async () => {
    setSavingData(true);

    const options = {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include'
    };

    try {

      const response = await fetch(`${SERVER_URL}/soybean/delete/${boletaData['id_boleta']}`, options);

      if (response.status === 204) setSearchParams({ 'content': 'Boletas Soja' });

      else setModalErrorOpenDelete(true);

      setSavingData(false);

    } catch (error) {

      setModalErrorOpenDelete(true);
      setSavingData(false);

    }

    setOpenModalDelete(false);
  }

  const currentYear = new Date().getFullYear();

  const years = [];

  for (let year = 2023; year <= currentYear; year++) {
    years.push(year.toString());
  }

  return (
    <>
      <ReturnButton />
      <Box style={styles.container} size='small' component='form' id='form-rally' noValidate>

        <Fieldset>

          {loading ? <CircularProgress style={styles.loadingAnimation} /> :

            <div>
              <Box>
                <Typography color='primary' variant='h4'>Geral</Typography>
                <Divider />

                <Grid container>

                  <Grid container item justifyContent='space-around'>

                    <Grid item>
                      <TextField
                        multiline
                        sx={{ width: '100%', mt: '10px' }}
                        label='ID Boleta'
                        name='id_boleta'
                        InputProps={{
                          startAdornment: <InputAdornment position='start'></InputAdornment>,
                          readOnly: true,
                        }}
                        defaultValue={boletaData['id_boleta']}
                        onChange={(e) => boletaData[e.target.name] = e.target.value}
                      />
                    </Grid>

                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <SelectField
                      label='Safra'
                      name='safra'
                      valueSelect={boletaData.safra}
                      dataList={boletaData}
                      options={years.reverse()}
                      width={'14em'}
                    />

                    <Grid item>
                      <TextFieldInput label='Data' name='data' dataList={boletaData} width='12em' />
                    </Grid>
                    <Grid item>
                      <SelectField
                        label='Equipe'
                        name='equipe'
                        valueSelect={boletaData.equipe}
                        dataList={boletaData}
                        options={['Equipe 1', 'Equipe 2', 'Equipe 3', 'Equipe 4', 'Equipe 5', 'Equipe 6', 'Equipe 7', 'Equipe 8',
                          'Equipe 9', 'Equipe 10', 'Equipe 11', 'Equipe 12', 'Equipe 13', 'Equipe 14', 'Equipe 15', 'Equipe 16', 'Equipe 17']}
                      />
                    </Grid>

                    <Grid item>
                      <SelectField
                        label='Responsável'
                        name='responsavel'
                        valueSelect={boletaData.responsavel}
                        dataList={boletaData}
                        options={['Ana Carolina Petry', 'Gildomar Lindemann', 'Henrique Eggers', 'Jailton Pereira', 'Marcelo Fornalski', 'Valmir Assarice', 'Vinícius Fernandes']}
                      />
                    </Grid>
                    <Grid item>
                      <SelectField
                        label='UF'
                        name='uf'
                        valueSelect={boletaData.uf}
                        dataList={boletaData}
                        options={['BA', 'DF', 'GO', 'MA', 'MG', 'MS', 'MT', 'PI', 'PR', 'RS', 'SC', 'TO']}
                      />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Município' name='municipio' dataList={boletaData} />
                    </Grid>

                    {boletaData['safra'] === '2024' && (
                      <Grid item>
                        <TextFieldInput label='Município da Geolocalização' name='municipio_geo' dataList={boletaData} />
                      </Grid>
                    )}

                    <Grid item>
                      <TextFieldInput label='Latitude' name='latitude' dataList={boletaData} />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Longitude' name='longitude' dataList={boletaData} />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Altitude' name='altitude' dataList={boletaData} />
                    </Grid>

                    <Grid container justifyContent='space-between'>
                      <Grid item width={'25%'}>
                        <TextFieldInput label='Amostra de Grãos' name='amostra_graos' dataList={boletaData} type='number' />
                      </Grid>

                      <Grid item width={'25%'}>
                        <TextFieldInput label='Peso 100 Grãos (g)' name='peso_graos' dataList={boletaData} type='string' />
                      </Grid>

                      <Grid item width={'25%'}>
                        <TextFieldInput label='Umidade 1 - 100 Grãos (g)' name='umidade_graos_1' dataList={boletaData} type='string' />
                      </Grid>

                      <Grid item>
                        <TextFieldInput label='Umidade 2 - 100 Grãos (g)' name='umidade_graos_2' dataList={boletaData} type='string' />
                      </Grid>
                    </Grid>

                    <TextField

                      multiline
                      sx={{ width: '50%', mt: '10px' }}
                      label='Observações'
                      name='observacao'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'></InputAdornment>,
                      }}
                      defaultValue={boletaData['observacao']}
                      onChange={(e) => boletaData[e.target.name] = e.target.value}
                    />

                  </Grid>

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Medições</Typography>
                <Divider />

                <Grid container >

                  <Grid container item justifyContent='space-around'>

                    <Grid item >
                      <TextFieldInput label='Espaçamento entre Linhas' name='espacamento_linhas' dataList={boletaData} type='number' />
                    </Grid>

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Número de Plantas (em 3 metros)</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={5} label='Linha' name='linha' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Número de Vagens</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={5} label='Vagem' name='vagem' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Número de Grãos</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={5} label='Grãos' name='grao' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Espaçamento entre Plantas (em 2 metros)</Typography>
                    </Grid>
                  </Grid>
                  <GroupFields dataList={boletaData} numInputs={48} label='Espaçamento' name='espacamento_plantas_' />

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Condições</Typography>
                <Divider />

                <Grid container item justifyContent='space-between'>

                  <Grid item >
                    <TextFieldInput label='Avaliação Visual (sc/ha)' name='avaliacao_visual' dataList={boletaData} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Irrigação' name='irrigacao' valueSelect={boletaData.irrigacao} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Condições da Lavoura' name='condicao_lavoura' valueSelect={boletaData['condicao_lavoura']} dataList={boletaData} options={['Muito Ruim', 'Ruim', 'Média', 'Boa', 'Excelente']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Estádio' name='estadio' valueSelect={boletaData.estadio} dataList={boletaData} options={['R6', 'R7', 'R8', 'R9']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Umidade do Solo' name='umidade_solo' valueSelect={boletaData['umidade_solo']} dataList={boletaData} options={['Rachaduras', 'Seco', 'Úmido', 'Muito Úmido', 'Poças']} width='12em' />
                  </Grid>

                  <Grid item >
                    <SelectField label='Declividade do Terreno' name='declividade_terreno' valueSelect={boletaData['declividade_terreno']} dataList={boletaData} options={['Plano', 'Pouco Inclinado', 'Muito Inclinado']} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Textura do Solo' name='textura_solo' valueSelect={boletaData['textura_solo']} dataList={boletaData} options={['Arenoso', 'Médio', 'Argiloso']} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Dessecação' name='dessecacao' valueSelect={boletaData.dessecacao} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Semeadura em Nível' name='semeadura_nivel' valueSelect={boletaData['semeadura_nivel']} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Sinais de Erosão' name='sinal_erosao' valueSelect={boletaData['sinal_erosao']} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item >
                    <SelectField label='Sinais de Compactação' name='sinal_compactacao' valueSelect={boletaData['sinal_compactacao']} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Nível de Compactação' name='nivel_compactacao' valueSelect={boletaData['nivel_compactacao']} dataList={boletaData} options={['Baixa', 'Média', 'Alta']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Terraceamento' name='terraceamento' valueSelect={boletaData.terraceamento} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Plantio Direto' name='plantio_direto' valueSelect={boletaData['plantio_direto']} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item>
                    <TextFieldInput label='Cobertura do Solo' name='cobertura_solo' dataList={boletaData} width='12em' />
                  </Grid>

                  <Grid item sx={{ width: '50%' }}>
                    <TextField

                      multiline
                      sx={{ width: '70%', mt: '10px' }}
                      label='Tipos Resíduos'
                      name='tipos_residuos'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'></InputAdornment>,
                      }}
                      defaultValue={boletaData['tipos_residuos']}
                      onChange={(e) => boletaData[e.target.name] = e.target.value}
                    />
                  </Grid>

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Infestações</Typography>
                <Divider />

                <Grid container item justifyContent='space-between'>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Pragas</Typography>
                    </Grid>
                  </Grid>

                  <RadioButton dataList={boletaData} name='lagarta_spodoptera' label={'Lagarta Gênero Spodoptera'} />

                  <RadioButton dataList={boletaData} name='lagarta_falsa_medideira' label={'Lagarta Falsa Madideira'} />

                  <RadioButton dataList={boletaData} name='lagarta_maca' label={'Lagarta da Maçã'} />

                  <RadioButton dataList={boletaData} name='mosca_branca' label={'Mosca Branca'} />

                  <RadioButton dataList={boletaData} name='percevejo' label={'Percevejo'} />

                  <RadioButton dataList={boletaData} name='vaquinha' label={'Vaquinha'} />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_1_valor' nameInput='praga_outro_1_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_2_valor' nameInput='praga_outro_2_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_3_valor' nameInput='praga_outro_3_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_4_valor' nameInput='praga_outro_4_nome' />

                </Grid>

                <Grid container item justifyContent='space-between'>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Daninhas</Typography>
                    </Grid>
                  </Grid>

                  <RadioButton dataList={boletaData} name='algodao_guaxo' label={'Algodão Guaxo'} />

                  <RadioButton dataList={boletaData} name='amargoso' label={'Amargoso'} />

                  <RadioButton dataList={boletaData} name='buva' label={'Buva'} />

                  <RadioButton dataList={boletaData} name='capim_pe_galinha' label={'Capim Pé de Galinha'} />

                  <RadioButton dataList={boletaData} name='carrapicho' label={'Carrapicho'} />

                  <RadioButton dataList={boletaData} name='corda_viola' label={'Corda de Viola'} />

                  <RadioButton dataList={boletaData} name='fedegoso' label={'Fedegoso'} />

                  <RadioButton dataList={boletaData} name='leiteiro' label={'Leiteiro'} />

                  <RadioButton dataList={boletaData} name='milho_guaxo' label={'Milho Guaxo'} />

                  <RadioButton dataList={boletaData} name='picao_preto' label={'Picão Preto'} />

                  <RadioButton dataList={boletaData} name='trapoeraba' label={'Trapoeraba'} />

                  <RadioButtonInput dataList={boletaData} nameRadio='daninha_outro_1_valor' nameInput='daninha_outro_1_nome' />

                </Grid>

                <Grid container item justifyContent='space-between'>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Doenças</Typography>
                    </Grid>
                  </Grid>

                  <RadioButton dataList={boletaData} name='antracnose' label={'Antracnose'} />

                  <RadioButton dataList={boletaData} name='cercospora' label={'Cercospora'} />

                  <RadioButton dataList={boletaData} name='dfc' label={'DFC'} />

                  <RadioButton dataList={boletaData} name='ferrugem' label={'Ferrugem'} />

                  <RadioButton dataList={boletaData} name='mancha_alvo' label={'Mancha Alvo'} />

                  <RadioButton dataList={boletaData} name='mofo_branco' label={'Mofo Branco'} />

                  <RadioButton dataList={boletaData} name='oidio' label={'Oídio'} />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_1_valor' nameInput='doenca_outro_1_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_2_valor' nameInput='doenca_outro_2_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_3_valor' nameInput='doenca_outro_3_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_4_valor' nameInput='doenca_outro_4_nome' />

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Produtor</Typography>
                <Divider />

                <Grid container>

                  <Grid container item justifyContent='space-around'>

                    <Grid item >
                      <TextFieldInput label='Nome' name='produtor_nome' dataList={boletaData} />
                    </Grid>
                    <Grid item>
                      <TextFieldInput label='Telefone (1)' name='produtor_tel_1' dataList={boletaData} />
                    </Grid>
                    <Grid item>
                      <TextFieldInput label='Telefone (2)' name='produtor_tel_2' dataList={boletaData} />
                    </Grid>

                  </Grid>

                  <Grid container item justifyContent='space-around'>
                    <Grid item>
                      <TextFieldInput label='Email' name='produtor_email' dataList={boletaData} />
                    </Grid>
                    <Grid item>
                      <TextFieldInput label='Fazenda' name='produtor_fazenda' dataList={boletaData} />
                    </Grid>
                  </Grid>

                </Grid>

              </Box>

              <Grid style={styles.buttonsBox}>
                <SaveButton handleAction={openModal} />

                <DeleteButton handleAction={openDeleteModal} text={'Excluir Boleta'} />
              </Grid>

              <Modal open={openModalDelete}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal}>Deseja excluir a boleta?</h2>
                  <div style={styles.containerButtonModal}>
                    <Button style={styles.buttonModal} variant='contained' onClick={() => handleDelete()}>{savingData ? <CircularProgress style={styles.loadingAnimationButton} /> : 'Excluir'}</Button>
                    <Button onClick={() => setOpenModalDelete(false)}>Cancelar</Button>
                  </div>
                </Box>
              </Modal>

              <Modal open={modalErrorOpenDelete}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal} id='parent-modal-title'>Falha ao excluir a boleta!</h2>
                  <Button variant='contained' onClick={() => setModalErrorOpenDelete(false)}>Ok</Button>
                </Box>
              </Modal>

              <Modal open={open}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal}>Deseja salvar a edição?</h2>
                  <div style={styles.containerButtonModal}>
                    <Button style={styles.buttonModal} variant='contained' onClick={() => handleSubmit()}>{savingData ? <CircularProgress style={styles.loadingAnimationButton} /> : 'Salvar'}</Button>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                  </div>
                </Box>
              </Modal>

              <Modal open={modalErrorOpen}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal} id='parent-modal-title'>Falha ao salvar a boleta!</h2>
                  <Button variant='contained' onClick={() => setModalErrorOpen(false)}>Ok</Button>
                </Box>
              </Modal>
            </div>

          }

        </Fieldset>

      </Box>
    </>

  );
}

const styles = {

  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  label: {

    color: 'white',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    paddingLeft: '10px',
    marginTop: '10px',

  },

  titleSegment: {

    marginTop: '30px'

  },

  modal: {

    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 120,
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  },

  titleModal: {

    marginBottom: '10px',

  },

  containerButtonModal: {

    width: '75%',
    display: 'flex',
    justifyContent: 'space-around',

  },

  loadingAnimation: {
    display: 'flex',
    width: '15%',
    height: '15%',
    alignSelf: 'center',
  },

  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: 20
  },

  buttonModal: {
    minWidth: '30%',
    minHeight: '30%'
  },

  loadingAnimationButton: {
    color: 'white',
    width: '20px',
    height: '20px'
  }

}

export default EditBoleta;