import { useContext } from 'react';
import { Button } from '@mui/material';
import { AuthContext } from '../../context/auth';

const SlimButton = ({ handleAction }) => {

  const { isAdmin } = useContext(AuthContext);

  return (
    <Button
      variant='outlined'
      color='primary'
      style={styles.button}
      onClick={handleAction}
    >
      {isAdmin ? 'Editar' : 'Visualizar'}
    </Button>
  );
}

const styles = {
  button: {
    height: '25px', 
    textTransform: 'none'
  }
}

export default SlimButton;