import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Box, Divider, Typography, Grid, TextField, InputAdornment, Modal, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { TextFieldInput, SelectField, GroupFields, RadioButton, RadioButtonInput, ReturnButton, SaveButton, DeleteButton, Fieldset } from '../../../components';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const EditBoleta = () => {
  const [open, setOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalErrorOpenDelete, setModalErrorOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [savingData, setSavingData] = useState(false);

  const theme = useTheme();

  const primaryColor = theme.palette.primary.main;

  const [searchParams, setSearchParams] = useSearchParams();

  const idBoletaParams = searchParams.get('boletaMilhoId');
  const contentParams = searchParams.get('content');

  const navigate = useNavigate();

  const selectedBoleta = JSON.parse(sessionStorage.getItem('editar_boleta_milho'));

  useEffect(() => {
    if (idBoletaParams !== selectedBoleta.id_boleta && contentParams === 'Editar Boleta Milho') navigate('/notfoundpage');

    setLoading(false);
  }, [idBoletaParams, selectedBoleta.id_boleta, contentParams, navigate]);

  const residuoMilho = selectedBoleta['residuo_milho'] ? 'Milho' : '';
  const residuoBraquiaria = selectedBoleta['residuo_braquiaria'] ? 'Braquiaria' : '';
  const residuoMilheto = selectedBoleta['residuo_milheto'] ? 'Milheto' : '';
  const residuoAlgodao = selectedBoleta['residuo_algodao'] ? 'Algodão' : '';
  const residuoGraminea = selectedBoleta['residuo_graminea'] ? 'Gramínea' : '';
  const residuoTrigo = selectedBoleta['residuo_trigo'] ? 'Trigo' : '';
  const residuoAveia = selectedBoleta['residuo_aveia'] ? 'Aveia' : '';
  const residuoOutro = selectedBoleta['residuo_outro_input'] ? selectedBoleta['residuo_outro_input'] : '';

  const residuos = [residuoMilho, residuoBraquiaria, residuoMilheto, residuoAlgodao, residuoGraminea, residuoTrigo, residuoAveia, residuoOutro].filter(Boolean).join(', ');

  const [boletaData] = useState({
    'id_boleta': selectedBoleta['id_boleta'],
    'safra': selectedBoleta['safra'],
    'data': selectedBoleta['data'],
    'equipe': selectedBoleta['equipe'],
    'responsavel': selectedBoleta['responsavel'],
    'uf': selectedBoleta['uf'],
    'municipio': selectedBoleta['municipio'],
    'municipio_geo': selectedBoleta['municipio_geo'],
    'latitude': selectedBoleta['latitude'],
    'longitude': selectedBoleta['longitude'],
    'altitude': selectedBoleta['altitude'],
    'observacao': selectedBoleta['observacao'],
    'amostra_graos': selectedBoleta['amostra_graos'],
    'peso_graos': selectedBoleta['peso_graos'],
    'umidade_graos_1': selectedBoleta['umidade_graos_1'],
    'umidade_graos_2': selectedBoleta['umidade_graos_2'],

    'espacamento_linhas': selectedBoleta['espacamento_linhas'] || selectedBoleta['espacamento_linhas_outro'],
    'plantas_linha1': selectedBoleta['plantas_linha1'],
    'plantas_linha2': selectedBoleta['plantas_linha2'],
    'plantas_linha3': selectedBoleta['plantas_linha3'],
    'espigas_linha1': selectedBoleta['espigas_linha1'],
    'espigas_linha2': selectedBoleta['espigas_linha2'],
    'espigas_linha3': selectedBoleta['espigas_linha3'],
    'fileiras_graos_espiga1': selectedBoleta['fileiras_graos_espiga1'],
    'fileiras_graos_espiga2': selectedBoleta['fileiras_graos_espiga2'],
    'fileiras_graos_espiga3': selectedBoleta['fileiras_graos_espiga3'],
    'fileiras_graos_espiga4': selectedBoleta['fileiras_graos_espiga4'],
    'fileiras_graos_espiga5': selectedBoleta['fileiras_graos_espiga5'],
    'fileiras_graos_espiga6': selectedBoleta['fileiras_graos_espiga6'],
    'fileiras_graos_espiga7': selectedBoleta['fileiras_graos_espiga7'],
    'fileiras_graos_espiga8': selectedBoleta['fileiras_graos_espiga8'],
    'fileiras_graos_espiga9': selectedBoleta['fileiras_graos_espiga9'],
    'fileiras_graos_espiga10': selectedBoleta['fileiras_graos_espiga10'],
    'graos_fileiras_espiga1': selectedBoleta['graos_fileiras_espiga1'],
    'graos_fileiras_espiga2': selectedBoleta['graos_fileiras_espiga2'],
    'graos_fileiras_espiga3': selectedBoleta['graos_fileiras_espiga3'],
    'graos_fileiras_espiga4': selectedBoleta['graos_fileiras_espiga4'],
    'graos_fileiras_espiga5': selectedBoleta['graos_fileiras_espiga5'],
    'graos_fileiras_espiga6': selectedBoleta['graos_fileiras_espiga6'],
    'graos_fileiras_espiga7': selectedBoleta['graos_fileiras_espiga7'],
    'graos_fileiras_espiga8': selectedBoleta['graos_fileiras_espiga8'],
    'graos_fileiras_espiga9': selectedBoleta['graos_fileiras_espiga9'],
    'graos_fileiras_espiga10': selectedBoleta['graos_fileiras_espiga10'],
    'comprimento_espiga1': selectedBoleta['comprimento_espiga1'],
    'comprimento_espiga2': selectedBoleta['comprimento_espiga2'],
    'comprimento_espiga3': selectedBoleta['comprimento_espiga3'],
    'comprimento_espiga4': selectedBoleta['comprimento_espiga4'],
    'comprimento_espiga5': selectedBoleta['comprimento_espiga5'],
    'comprimento_espiga6': selectedBoleta['comprimento_espiga6'],
    'comprimento_espiga7': selectedBoleta['comprimento_espiga7'],
    'comprimento_espiga8': selectedBoleta['comprimento_espiga8'],
    'comprimento_espiga9': selectedBoleta['comprimento_espiga9'],
    'comprimento_espiga10': selectedBoleta['comprimento_espiga10'],
    'diametro_espiga1': selectedBoleta['diametro_espiga1'],
    'diametro_espiga2': selectedBoleta['diametro_espiga2'],
    'diametro_espiga3': selectedBoleta['diametro_espiga3'],
    'diametro_espiga4': selectedBoleta['diametro_espiga4'],
    'diametro_espiga5': selectedBoleta['diametro_espiga5'],
    'diametro_espiga6': selectedBoleta['diametro_espiga6'],
    'diametro_espiga7': selectedBoleta['diametro_espiga7'],
    'diametro_espiga8': selectedBoleta['diametro_espiga8'],
    'diametro_espiga9': selectedBoleta['diametro_espiga9'],
    'diametro_espiga10': selectedBoleta['diametro_espiga10'],

    'avaliacao_visual': selectedBoleta['avaliacao_visual'],
    'irrigacao': selectedBoleta['irrigacao'],
    'condicao_lavoura': selectedBoleta['condicao_lavoura'],
    'estadio': selectedBoleta['estadio'],
    'umidade_solo': selectedBoleta['umidade_solo'],
    'declividade_terreno': selectedBoleta['declividade_terreno'],
    'textura_solo': selectedBoleta['textura_solo'],
    'semeadura_nivel': selectedBoleta['semeadura_nivel'],
    'sinal_erosao': selectedBoleta['sinal_erosao'],
    'sinal_compactacao': selectedBoleta['sinal_compactacao'],
    'nivel_compactacao': selectedBoleta['nivel_compactacao'] || '',
    'terraceamento': selectedBoleta['terraceamento'],
    'plantio_direto': selectedBoleta['plantio_direto'],
    'cobertura_solo': selectedBoleta['cobertura_solo'],
    'cobertura_viva': selectedBoleta['cobertura_viva'],
    'cobertura_viva_input': selectedBoleta['cobertura_viva_input'],
    'tipos_residuos': residuos || selectedBoleta['tipos_residuos'],

    'cigarrinha': selectedBoleta['cigarrinha'],
    'falsa_medideira': selectedBoleta['falsa_medideira'],
    'lagarta_cartucho': selectedBoleta['lagarta_cartucho'],
    'lagarta_espiga': selectedBoleta['lagarta_espiga'],
    'percevejo': selectedBoleta['percevejo'],
    'pulgao': selectedBoleta['pulgao'],
    'vaquinha': selectedBoleta['vaquinha'],
    'praga_outro_1_nome': selectedBoleta['praga_outro_1_nome'],
    'praga_outro_1_valor': selectedBoleta['praga_outro_1_valor'],
    'praga_outro_2_nome': selectedBoleta['praga_outro_2_nome'],
    'praga_outro_2_valor': selectedBoleta['praga_outro_2_valor'],
    'praga_outro_3_nome': selectedBoleta['praga_outro_3_nome'],
    'praga_outro_3_valor': selectedBoleta['praga_outro_3_valor'],
    'praga_outro_4_nome': selectedBoleta['praga_outro_4_nome'],
    'praga_outro_4_valor': selectedBoleta['praga_outro_4_valor'],

    'buva': selectedBoleta['buva'],
    'capim_amargoso': selectedBoleta['capim_amargoso'],
    'capim_colchao': selectedBoleta['capim_colchao'],
    'corda_viola': selectedBoleta['corda_viola'],
    'erva_santa_luzia': selectedBoleta['erva_santa_luzia'],
    'fedegoso': selectedBoleta['fedegoso'],
    'leiteiro': selectedBoleta['leiteiro'],
    'pe_galinha': selectedBoleta['pe_galinha'],
    'picao_preto': selectedBoleta['picao_preto'],
    'poaia_branca': selectedBoleta['poaia_branca'],
    'soja_guaxa': selectedBoleta['soja_guaxa'],
    'trapoeraba': selectedBoleta['trapoeraba'],
    'vassourinha_botao': selectedBoleta['vassourinha_botao'],
    'daninha_outro_1_nome': selectedBoleta['daninha_outro_1_nome'],
    'daninha_outro_1_valor': selectedBoleta['daninha_outro_1_valor'],
    'daninha_outro_2_nome': selectedBoleta['daninha_outro_2_nome'],
    'daninha_outro_2_valor': selectedBoleta['daninha_outro_2_valor'],
    'daninha_outro_3_nome': selectedBoleta['daninha_outro_3_nome'],
    'daninha_outro_3_valor': selectedBoleta['daninha_outro_3_valor'],
    'daninha_outro_4_nome': selectedBoleta['daninha_outro_4_nome'],
    'daninha_outro_4_valor': selectedBoleta['daninha_outro_4_valor'],

    'carvao': selectedBoleta['carvao'],
    'cercosporiose': selectedBoleta['cercosporiose'],
    'enfezamento': selectedBoleta['enfezamento'],
    'ferrugem': selectedBoleta['ferrugem'],
    'fumagina': selectedBoleta['fumagina'],
    'helmintosporiose': selectedBoleta['helmintosporiose'],
    'mancha_branca': selectedBoleta['mancha_branca'],
    'mancha_bipolaris': selectedBoleta['mancha_bipolaris'],
    'mancha_diplodia': selectedBoleta['mancha_diplodia'],
    'doenca_outro_1_nome': selectedBoleta['doenca_outro_1_nome'],
    'doenca_outro_1_valor': selectedBoleta['doenca_outro_1_valor'],
    'doenca_outro_2_nome': selectedBoleta['doenca_outro_2_nome'],
    'doenca_outro_2_valor': selectedBoleta['doenca_outro_2_valor'],
    'doenca_outro_3_nome': selectedBoleta['doenca_outro_3_nome'],
    'doenca_outro_3_valor': selectedBoleta['doenca_outro_3_valor'],
    'doenca_outro_4_nome': selectedBoleta['doenca_outro_4_nome'],
    'doenca_outro_4_valor': selectedBoleta['doenca_outro_4_valor'],

    'produtor_nome': selectedBoleta['produtor_nome'],
    'produtor_tel_1': selectedBoleta['produtor_tel_1'],
    'produtor_tel_2': selectedBoleta['produtor_tel_2'],
    'produtor_email': selectedBoleta['produtor_email'],
    'produtor_fazenda': selectedBoleta['produtor_fazenda'],

  });

  const openDeleteModal = () => setOpenModalDelete(true);
  const openModal = () => setOpen(true);

  const handleSubmit = async () => {

    setSavingData(true);

    const options = {
      method: 'PUT',
      body: JSON.stringify(boletaData),
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include'
    };

    try {
      const response = await fetch(`${SERVER_URL}/corn/update`, options);

      if (response.status === 200) setSearchParams({ 'content': 'Boletas Milho' });
      else setModalErrorOpen(true);
      setSavingData(false);

    } catch (error) {

      setModalErrorOpen(true);
      setSavingData(false);

    }

    setOpen(false);

  };

  const handleDelete = async () => {
    setSavingData(true);

    const options = {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include'
    };

    try {

      const response = await fetch(`${SERVER_URL}/corn/delete/${boletaData['id_boleta']}`, options);

      if (response.status === 204) setSearchParams({ 'content': 'Boletas Milho' });

      else setModalErrorOpenDelete(true);

      setSavingData(false);

    } catch (error) {

      setModalErrorOpenDelete(true);
      setSavingData(false);

    }

    setOpenModalDelete(false);
  }

  const currentYear = new Date().getFullYear();

  const years = [];

  for (let year = 2023; year <= currentYear; year++) {
    years.push(year.toString());
  }

  return (
    <>

      <ReturnButton />
      <Box style={styles.container} size='small' component='form' id='form-rally' noValidate>

        <Fieldset>

          {loading ? <CircularProgress style={styles.loadingAnimation} /> :

            <div>
              <Box>
                <Typography color='primary' variant='h4'>Geral</Typography>
                <Divider />

                <Grid container>

                  <Grid container item justifyContent='space-around'>

                    <Grid item >
                      <TextField
                        multiline
                        sx={{ width: '100%', mt: '10px' }}
                        label='ID Boleta'
                        name='id_boleta'
                        InputProps={{
                          startAdornment: <InputAdornment position='start'></InputAdornment>,
                          readOnly: true,
                        }}
                        defaultValue={boletaData['id_boleta']}
                        onChange={(e) => boletaData[e.target.name] = e.target.value}
                      />
                    </Grid>

                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <Grid item>
                      <SelectField
                        label='Safra'
                        name='safra'
                        valueSelect={boletaData.safra}
                        dataList={boletaData}
                        options={years.reverse()}
                        width={'14em'}
                      />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Data' name='data' dataList={boletaData} width='12em' />
                    </Grid>
                    <Grid item>
                      <SelectField
                        label='Equipe'
                        name='equipe'
                        valueSelect={boletaData.equipe}
                        dataList={boletaData}
                        options={['Equipe 18', 'Equipe 19', 'Equipe 20', 'Equipe 21', 'Equipe 22', 'Equipe 23']}
                      />
                    </Grid>

                    <Grid item>
                      <SelectField
                        label='Responsável'
                        name='responsavel'
                        valueSelect={boletaData.responsavel}
                        dataList={boletaData}
                        options={['Angelo Ilario', 'Danilo Mazakina', 'Gelson Matheus', 'Jailton Pereira', 'Janaine Toso', 'Valmir Assarice']}
                      />
                    </Grid>
                    <Grid item>
                      <SelectField
                        label='UF'
                        name='uf'
                        valueSelect={boletaData.uf}
                        dataList={boletaData}
                        options={['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']}
                      />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Município' name='municipio' dataList={boletaData} />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Município da Geolocalização' name='municipio_geo' dataList={boletaData} />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Latitude' name='latitude' dataList={boletaData} />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Longitude' name='longitude' dataList={boletaData} />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Altitude' name='altitude' dataList={boletaData} />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Amostra de Grãos' name='amostra_graos' dataList={boletaData} type='number' />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Peso 100 Grãos (g)' name='peso_graos' dataList={boletaData} type='string' />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Umidade 1 - 100 Grãos (g)' name='umidade_graos_1' dataList={boletaData} type='string' />
                    </Grid>

                    <Grid item>
                      <TextFieldInput label='Umidade 2 - 100 Grãos (g)' name='umidade_graos_2' dataList={boletaData} type='string' />
                    </Grid>

                    <TextField

                      multiline
                      sx={{ width: '50%', mt: '10px' }}
                      label='Observações'
                      name='observacao'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'></InputAdornment>,
                      }}
                      defaultValue={boletaData['observacao']}
                      onChange={(e) => boletaData[e.target.name] = e.target.value}
                    />

                  </Grid>

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Medições</Typography>
                <Divider />

                <Grid container >

                  <Grid container item justifyContent='space-around'>

                    <Grid item >
                      <TextFieldInput label='Espaçamento entre Linhas' name='espacamento_linhas' dataList={boletaData} type='number' />
                    </Grid>

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Número de Plantas (em 10 metros)</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={3} label='Linha' name='plantas_linha' width='30%' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Número de Espigas (em 10 metros)</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={3} label='Número de Espigas' name='espigas_linha' width='30%' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Número de Fileiras de Grãos</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={10} label='Espiga' name='fileiras_graos_espiga' width='20%' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Número de Grãos/Fileiras</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={10} label='Espiga' name='graos_fileiras_espiga' width='20%' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Comprimento das Espigas (cm)</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={10} label='Espiga' name='comprimento_espiga' width='20%' />

                  </Grid>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Diâmetro das Espigas (cm)</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item justifyContent='space-between'>

                    <GroupFields dataList={boletaData} numInputs={10} label='Espiga' name='diametro_espiga' width='20%' />

                  </Grid>

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Condições</Typography>
                <Divider />

                <Grid container item justifyContent='space-between'>

                  <Grid item >
                    <TextFieldInput label='Avaliação Visual (sc/ha)' name='avaliacao_visual' dataList={boletaData} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Irrigação' name='irrigacao' valueSelect={boletaData.irrigacao} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Condições da Lavoura' name='condicao_lavoura' valueSelect={boletaData['condicao_lavoura']} dataList={boletaData} options={['Muito Ruim', 'Ruim', 'Média', 'Boa', 'Excelente']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Estádio do Grão' name='estadio' valueSelect={boletaData.estadio} dataList={boletaData} options={['Leitoso', 'Farinácio', 'Maturação Fisiológica', 'Pastoso', 'Duro']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Umidade do Solo' name='umidade_solo' valueSelect={boletaData['umidade_solo']} dataList={boletaData} options={['Rachaduras', 'Seco', 'Úmido', 'Muito Úmido', 'Poças']} width='12em' />
                  </Grid>

                  <Grid item >
                    <SelectField label='Declividade do Terreno' name='declividade_terreno' valueSelect={boletaData['declividade_terreno']} dataList={boletaData} options={['Plano', 'Pouco Inclinado', 'Muito Inclinado']} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Textura do Solo' name='textura_solo' valueSelect={boletaData['textura_solo']} dataList={boletaData} options={['Arenoso', 'Médio', 'Argiloso']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Semeadura em Nível' name='semeadura_nivel' valueSelect={boletaData['semeadura_nivel']} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Sinais de Erosão' name='sinal_erosao' valueSelect={boletaData['sinal_erosao']} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item >
                    <SelectField label='Sinais de Compactação' name='sinal_compactacao' valueSelect={boletaData['sinal_compactacao']} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>
                  <Grid item>
                    <SelectField label='Nível de Compactação' name='nivel_compactacao' valueSelect={boletaData['nivel_compactacao']} dataList={boletaData} options={['Baixa', 'Média', 'Alta']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Terraceamento' name='terraceamento' valueSelect={boletaData.terraceamento} dataList={boletaData} options={['Sim', 'Não']} width='12em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Plantio Direto' name='plantio_direto' valueSelect={boletaData['plantio_direto']} dataList={boletaData} options={['Sim', 'Não']} width='14em' />
                  </Grid>

                  <Grid item>
                    <TextFieldInput label='Cobertura do Solo' name='cobertura_solo' dataList={boletaData} width='14em' />
                  </Grid>

                  <Grid item>
                    <SelectField label='Cobertura Viva' name='cobertura_viva' valueSelect={boletaData['cobertura_viva']} dataList={boletaData} options={['Sim', 'Não']} width='14em' />
                  </Grid>

                  <Grid item>
                    <TextFieldInput label='Qual tipo de cobertura viva?' name='cobertura_viva_input' dataList={boletaData} width='14em' />
                  </Grid>

                  <Grid item sx={{ width: '50%' }}>
                    <TextField

                      multiline
                      sx={{ width: '70%', mt: '10px' }}
                      label='Tipos Resíduos'
                      name='tipos_residuos'
                      InputProps={{
                        startAdornment: <InputAdornment position='start'></InputAdornment>,
                      }}
                      defaultValue={boletaData['tipos_residuos']}
                      onChange={(e) => boletaData[e.target.name] = e.target.value}
                    />
                  </Grid>

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Infestações</Typography>
                <Divider />

                <Grid container item justifyContent='space-between'>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Pragas</Typography>
                    </Grid>
                  </Grid>

                  <RadioButton dataList={boletaData} name='cigarrinha' label={'Cigarrinha'} />

                  <RadioButton dataList={boletaData} name='falsa_medideira' label={'Falsa Medideira'} />

                  <RadioButton dataList={boletaData} name='lagarta_cartucho' label={'Lagarta do Cartucho'} />

                  <RadioButton dataList={boletaData} name='lagarta_espiga' label={'Lagarta da Espiga'} />

                  <RadioButton dataList={boletaData} name='percevejo' label={'Percevejo'} />

                  <RadioButton dataList={boletaData} name='pulgao' label={'Pulgão'} />

                  <RadioButton dataList={boletaData} name='vaquinha' label={'Vaquinha'} />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_1_valor' nameInput='praga_outro_1_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_2_valor' nameInput='praga_outro_2_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_3_valor' nameInput='praga_outro_3_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='praga_outro_4_valor' nameInput='praga_outro_4_nome' />

                </Grid>

                <Grid container item justifyContent='space-between'>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Daninhas</Typography>
                    </Grid>
                  </Grid>

                  <RadioButton dataList={boletaData} name='buva' label={'Buva'} />

                  <RadioButton dataList={boletaData} name='capim_amargoso' label={'Capim Amargoso'} />

                  <RadioButton dataList={boletaData} name='capim_colchao' label={'Capim Colchão'} />

                  <RadioButton dataList={boletaData} name='corda_viola' label={'Corda de Viola'} />

                  <RadioButton dataList={boletaData} name='erva_santa_luzia' label={'Erva de Santa Luzia'} />

                  <RadioButton dataList={boletaData} name='fedegoso' label={'Fedegoso'} />

                  <RadioButton dataList={boletaData} name='leiteiro' label={'Leiteiro'} />

                  <RadioButton dataList={boletaData} name='pe_galinha' label={'Pé de Galinha'} />

                  <RadioButton dataList={boletaData} name='picao_preto' label={'Picão Preto'} />

                  <RadioButton dataList={boletaData} name='poaia_branca' label={'Poaia Branca'} />

                  <RadioButton dataList={boletaData} name='soja_guaxa' label={'Soja Guaxa'} />
                  
                  <RadioButton dataList={boletaData} name='trapoeraba' label={'Trapoeraba'} />

                  <RadioButton dataList={boletaData} name='vassourinha_botao' label={'Vassourinha de Botão'} />

                  <RadioButtonInput dataList={boletaData} nameRadio='daninha_outro_1_valor' nameInput='daninha_outro_1_nome' />
                  <RadioButtonInput dataList={boletaData} nameRadio='daninha_outro_2_valor' nameInput='daninha_outro_2_nome' />
                  <RadioButtonInput dataList={boletaData} nameRadio='daninha_outro_3_valor' nameInput='daninha_outro_3_nome' />
                  <RadioButtonInput dataList={boletaData} nameRadio='daninha_outro_4_valor' nameInput='daninha_outro_4_nome' />

                </Grid>

                <Grid container item justifyContent='space-between'>

                  <Grid container item style={{ ...styles.label, backgroundColor: primaryColor }}>
                    <Grid item>
                      <Typography>Doenças</Typography>
                    </Grid>
                  </Grid>

                  <RadioButton dataList={boletaData} name='carvao' label={'Carvão'} />

                  <RadioButton dataList={boletaData} name='cercosporiose' label={'Cercosporiose'} />

                  <RadioButton dataList={boletaData} name='enfezamento' label={'Enfezamento'} />

                  <RadioButton dataList={boletaData} name='ferrugem' label={'Ferrugem'} />

                  <RadioButton dataList={boletaData} name='fumagina' label={'Fumagina'} />

                  <RadioButton dataList={boletaData} name='helmintosporiose' label={'Helmintosporiose'} />

                  <RadioButton dataList={boletaData} name='mancha_branca' label={'Mancha Branca'} />

                  <RadioButton dataList={boletaData} name='mancha_bipolaris' label={'Mancha de Bipolaris'} />

                  <RadioButton dataList={boletaData} name='mancha_diplodia' label={'Mancha de Diplodia'} />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_1_valor' nameInput='doenca_outro_1_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_2_valor' nameInput='doenca_outro_2_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_3_valor' nameInput='doenca_outro_3_nome' />

                  <RadioButtonInput dataList={boletaData} nameRadio='doenca_outro_4_valor' nameInput='doenca_outro_4_nome' />

                </Grid>

                <Typography style={styles.titleSegment} color='primary' variant='h4'>Produtor</Typography>
                <Divider />

                <Grid container>

                  <Grid container item justifyContent='space-around'>

                    <Grid item >
                      <TextFieldInput label='Nome' name='produtor_nome' dataList={boletaData} />
                    </Grid>
                    <Grid item>
                      <TextFieldInput label='Telefone (1)' name='produtor_tel_1' dataList={boletaData} />
                    </Grid>
                    <Grid item>
                      <TextFieldInput label='Telefone (2)' name='produtor_tel_2' dataList={boletaData} />
                    </Grid>

                  </Grid>

                  <Grid container item justifyContent='space-around'>
                    <Grid item>
                      <TextFieldInput label='Email' name='produtor_email' dataList={boletaData} />
                    </Grid>
                    <Grid item>
                      <TextFieldInput label='Fazenda' name='produtor_fazenda' dataList={boletaData} />
                    </Grid>
                  </Grid>

                </Grid>

              </Box>

              <Grid style={styles.buttonsBox}>
                <SaveButton handleAction={openModal} />

                <DeleteButton handleAction={openDeleteModal} text={'Excluir Boleta'} />
              </Grid>

              <Modal open={openModalDelete}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal}>Deseja excluir a boleta?</h2>
                  <div style={styles.containerButtonModal}>
                    <Button style={styles.buttonModal} variant='contained' onClick={() => handleDelete()}>{savingData ? <CircularProgress style={styles.loadingAnimationButton} /> : 'Excluir'}</Button>
                    <Button onClick={() => setOpenModalDelete(false)}>Cancelar</Button>
                  </div>
                </Box>
              </Modal>

              <Modal open={modalErrorOpenDelete}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal} id='parent-modal-title'>Falha ao excluir a boleta!</h2>
                  <Button variant='contained' onClick={() => setModalErrorOpenDelete(false)}>Ok</Button>
                </Box>
              </Modal>

              <Modal open={open}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal}>Deseja salvar a edição?</h2>
                  <div style={styles.containerButtonModal}>
                    <Button style={styles.buttonModal} variant='contained' onClick={() => handleSubmit()}>{savingData ? <CircularProgress style={styles.loadingAnimationButton} /> : 'Salvar'}</Button>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                  </div>
                </Box>
              </Modal>

              <Modal open={modalErrorOpen}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal} id='parent-modal-title'>Falha ao salvar a boleta!</h2>
                  <Button variant='contained' onClick={() => setModalErrorOpen(false)}>Ok</Button>
                </Box>
              </Modal>
            </div>

          }

        </Fieldset>


      </Box>
    </>

  );
}

const styles = {

  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  label: {
    color: 'white',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    paddingLeft: '10px',
    marginTop: '10px',
  },

  titleSegment: {
    marginTop: '30px'
  },

  modal: {

    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 120,
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  },

  titleModal: {

    marginBottom: '10px',

  },

  containerButtonModal: {

    width: '75%',
    display: 'flex',
    justifyContent: 'space-around',

  },

  loadingAnimation: {
    display: 'flex',
    width: '15%',
    height: '15%',
    alignSelf: 'center',
  },

  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: 20
  },

  buttonModal: {
    minWidth: '30%',
    minHeight: '30%'
  },

  loadingAnimationButton: {
    color: 'white',
    width: '20px',
    height: '20px'
  }

}

export default EditBoleta;