import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Box, Divider, Typography, Grid, TextField, InputAdornment, Modal, CircularProgress } from '@mui/material';

import { TextFieldInput, SelectField, ReturnButton, SaveButton, DeleteButton, Fieldset } from '../../../components';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const EditQuilometragem = () => {
  const [open, setOpen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalErrorOpenDelete, setModalErrorOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [savingData, setSavingData] = useState(false);
  const [totalPercorrido, setTotalPercorrido] = useState('');

  const [searchParams, setSearchParams] = useSearchParams('');

  const idQuilometragemParams = searchParams.get('quilometragemId');
  const contentParams = searchParams.get('content');

  const navigate = useNavigate();

  const openDeleteModal = () => setOpenModalDelete(true);
  const openModal = () => setOpen(true);

  const selectedQuilometragem = JSON.parse(sessionStorage.getItem('editar_quilometragem'));

  useEffect(() => {

    if (idQuilometragemParams !== selectedQuilometragem.id_quilometragem && contentParams === 'Editar Quilometragem') navigate('/notfoundpage');

    setLoading(false);

  }, [idQuilometragemParams, selectedQuilometragem.id_quilometragem, contentParams, navigate]);

  const [quilometragemData] = useState({

    'id_quilometragem': selectedQuilometragem['id_quilometragem'],
    'safra': selectedQuilometragem['safra'],
    'data': selectedQuilometragem['data'],
    'equipe': selectedQuilometragem['equipe'],
    'motorista': selectedQuilometragem['motorista'],
    'km_inicial': selectedQuilometragem['km_inicial'],
    'km_final': selectedQuilometragem['km_final'],
    'total_percorrido': totalPercorrido || selectedQuilometragem['total_percorrido'],
    'percurso': selectedQuilometragem['percurso'],
    'placa': selectedQuilometragem['placa'],

  });

  const handleSubmit = async () => {
    setSavingData(true);

    const options = {
      method: 'PUT',
      body: JSON.stringify(quilometragemData),
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include'
    };

    try {

      const response = await fetch(`${SERVER_URL}/kilometer/update`, options);

      if (response.status === 200) setSearchParams({ 'content': 'Quilometragem' });
      else setModalErrorOpen(true);

      setSavingData(false);

    } catch (error) {

      setModalErrorOpen(true);
      setSavingData(false);

    }

    setOpen(false);

  };

  const handleDelete = async () => {
    setSavingData(true);

    const options = {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include'
    };

    try {

      const response = await fetch(`${SERVER_URL}/kilometer/delete/${quilometragemData['id_quilometragem']}`, options);

      if (response.status === 204) setSearchParams({ 'content': 'Quilometragem' });
      else setModalErrorOpenDelete(true);

      setSavingData(false);

    } catch (error) {

      setModalErrorOpenDelete(true);
      setSavingData(false);

    }

    setOpenModalDelete(false);
  }


  const currentYear = new Date().getFullYear();

  const years = [];

  for (let year = 2023; year <= currentYear; year++) {
    years.push(year.toString());
  }

  return (
    <>
      <ReturnButton />
      <form style={{ ...styles.container }} size='small' id='form-rally' noValidate>

        <Fieldset>

          {loading ? <CircularProgress style={styles.loadingAnimation} /> :

            <div>
              <Box>
                <Typography color='primary' variant='h4'>Dados Quilometragem</Typography>
                <Divider />

                <Grid container item justifyContent='space-between'>

                  <Grid container item justifyContent='space-around'>

                    <Grid item >
                      <TextField
                        multiline
                        sx={{ width: '100%', mt: '10px' }}
                        label='ID Quilometragem'
                        name='id_quilometragem'
                        InputProps={{
                          startAdornment: <InputAdornment position='start'></InputAdornment>,
                          readOnly: true,
                        }}
                        defaultValue={quilometragemData['id_quilometragem']}
                        onChange={(e) => quilometragemData[e.target.name] = e.target.value}
                      />
                    </Grid>

                  </Grid>

                  <Grid item>
                    <SelectField
                      label='Safra'
                      name='safra'
                      valueSelect={quilometragemData.safra}
                      dataList={quilometragemData}
                      options={years.reverse()}
                      width={'14em'}
                    />
                  </Grid>

                  <Grid item >
                    <TextFieldInput label='Data' name='data' dataList={quilometragemData} width='14em' />
                  </Grid>

                  <Grid item>
                    <SelectField
                      label='Equipe'
                      name='equipe'
                      valueSelect={quilometragemData.equipe}
                      dataList={quilometragemData}
                      options={['Equipe 1', 'Equipe Extra 1', 'Equipe 2', 'Equipe Extra 2', 'Equipe 3', 'Equip Extra 3', 'Equipe 4', 'Equipe 5',
                        'Equipe 6', 'Equipe 7', 'Equipe 8', 'Equipe 9', 'Equipe 10', 'Equipe 11', 'Equipe 12', 'Equipe 13', 'Equipe 14', 'Equipe 15',
                        'Equipe 16', 'Equipe 17', 'Equipe 18', 'Equipe 19', 'Equipe 20', 'Equipe 21', 'Equipe 22', 'Equipe 23'
                      ]}
                      width='14em'
                    />
                  </Grid>

                  <Grid item>
                    <TextFieldInput label='Placa' name='placa' dataList={quilometragemData} width='14em' />
                  </Grid>

                  <Grid item>
                    <SelectField
                      label='Motorista'
                      name='motorista'
                      valueSelect={quilometragemData.motorista}
                      dataList={quilometragemData}
                      options={['Angelo Ilario', 'Gelson Matheus', 'Gildomar Lindemann', 'Henrique Eggers', 'Jailton Pereira', 'Janaine Toso', 'João Piologo', 'João Ferreira', 'Lara Ribeiro', 'Lucas Solle', 'Marcelo Lima', 'Rodrigo Becker', 'Valmir Assarice', 'Vinícius Fernandes']}
                      width='14em' />
                  </Grid>

                  <Grid item>
                    <TextField
                      sx={{ width: '14em' }}
                      margin='normal'
                      label={'Km Inicial'}
                      name={'km_inicial'}
                      defaultValue={quilometragemData['km_inicial']}
                      style={styles.input}
                      onChange={(e) => {
                        quilometragemData[e.target.name] = e.target.value
                        quilometragemData['total_percorrido'] = quilometragemData['km_final'] - e.target.value;
                        setTotalPercorrido(quilometragemData['km_final'] - e.target.value)
                      }}
                      type='number'
                      InputProps={{
                        startAdornment: <InputAdornment position='start' />,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      sx={{ width: '14em' }}
                      margin='normal'
                      label={'Km Final'}
                      name={'km_final'}
                      defaultValue={quilometragemData['km_final']}
                      style={styles.input}
                      onChange={(e) => {
                        quilometragemData[e.target.name] = e.target.value
                        quilometragemData['total_percorrido'] = e.target.value - quilometragemData['km_inicial'];
                        setTotalPercorrido(e.target.value - quilometragemData['km_inicial']);
                      }}
                      type='number'
                      InputProps={{
                        startAdornment: <InputAdornment position='start' />,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      sx={{ width: '14em' }}
                      margin='normal'
                      label={'Total Percorrido'}
                      name={'total_percorrido'}
                      value={totalPercorrido || quilometragemData['total_percorrido']}
                      style={styles.input}
                      onChange={(e) => { quilometragemData[e.target.name] = e.target.value }}
                      InputProps={{
                        startAdornment: <InputAdornment position='start' />,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <TextFieldInput label='Percurso' name='percurso' dataList={quilometragemData} width={'14em'} />
                  </Grid>

                </Grid>

              </Box>

              <Grid style={styles.buttonsBox}>
                <SaveButton handleAction={openModal} />

                <DeleteButton handleAction={openDeleteModal} text={'Excluir Quilometragem'} />
              </Grid>

              <Modal open={openModalDelete}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal}>Deseja excluir a quilometragem?</h2>
                  <div style={styles.containerButtonModal}>
                    <Button style={styles.buttonModal} variant='contained' onClick={() => handleDelete()}>{savingData ? <CircularProgress style={styles.loadingAnimationButton} /> : 'Excluir'}</Button>
                    <Button onClick={() => setOpenModalDelete(false)}>Cancelar</Button>
                  </div>
                </Box>
              </Modal>

              <Modal open={modalErrorOpenDelete}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal} id='parent-modal-title'>Falha ao excluir a quilometragem!</h2>
                  <Button variant='contained' onClick={() => setModalErrorOpenDelete(false)}>Ok</Button>
                </Box>
              </Modal>

              <Modal open={open}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal}>Deseja salvar a edição?</h2>
                  <div style={styles.containerButtonModal}>
                    <Button style={styles.buttonModal} variant='contained' onClick={() => handleSubmit()}>{savingData ? <CircularProgress style={styles.loadingAnimationButton} /> : 'Salvar'}</Button>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                  </div>
                </Box>
              </Modal>

              <Modal open={modalErrorOpen}>
                <Box style={styles.modal}>
                  <h2 style={styles.titleModal} id='parent-modal-title'>Falha ao salvar a quilometragem!</h2>
                  <Button variant='contained' onClick={() => setModalErrorOpen(false)}>Ok</Button>
                </Box>
              </Modal>
            </div>

          }
        </Fieldset>

      </form>
    </>

  );
}

const styles = {

  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  label: {
    color: 'white',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    paddingLeft: '10px',
    marginTop: '10px',
  },

  titleSegment: {
    marginTop: '30px'
  },

  modal: {
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 120,
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  titleModal: {
    marginBottom: '10px',
  },

  containerButtonModal: {
    width: '75%',
    display: 'flex',
    justifyContent: 'space-around',
  },

  loadingAnimation: {
    display: 'flex',
    width: '15%',
    height: '15%',
    alignSelf: 'center',
  },

  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: 20
  },

  buttonModal: {
    minWidth: '30%',
    minHeight: '30%'
  },

  loadingAnimationButton: {
    color: 'white',
    width: '20px',
    height: '20px'
  }

}

export default EditQuilometragem;