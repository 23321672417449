import { useContext } from 'react';
import { AuthContext } from '../../context/auth';

const DeleteButton = ({ handleAction, text }) => {

  const { isAdmin } = useContext(AuthContext);

  return (
    <button
      style={{...styles.button, display: isAdmin ? 'block' : 'none'}}
      onClick={(e) => {
        e.preventDefault();
        handleAction();
      }}
    >
      {text}
    </button>
  );
}

const styles = {
  button: {
    color: '#f30909',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    marginRight: '10px',
    textTransform: 'none',
    fontSize: '16px'
  }
}

export default DeleteButton;