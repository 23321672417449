import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';

const RadioButtonInput = ({ dataList, nameRadio, nameInput }) => {

  return (
    <>

      <TextField
        sx={{ fontSize: '16px', display: 'block', height: '5%' }}
        type='text'
        margin='normal'
        name={nameInput}
        defaultValue={dataList[nameInput]}
        onChange={(e) => dataList[e.target.name] = e.target.value}
      />

      <Grid item sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>

        <FormControl component='fieldset' >
          <RadioGroup
            name={nameRadio}
            row
            defaultValue={dataList[nameRadio]}

          >
            <FormControlLabel
              value='Alta Infestação'
              control={<Radio onClick={(e) => dataList[e.target.name] = e.target.value} />}
              label='Alta Infestação'
            />

            <FormControlLabel
              value='Possui'
              control={<Radio onClick={(e) => dataList[e.target.name] = e.target.value} />}
              label='Possui'
            />

            <FormControlLabel
              value=''
              control={<Radio onClick={(e) => dataList[e.target.name] = e.target.value} />}
              label='Não Possui'
            />

          </RadioGroup>
        </FormControl>
      </Grid>
    </>


  )
}

export default RadioButtonInput;