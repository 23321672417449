import { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SpeedIcon from '@mui/icons-material/Speed';
import Logout from '@mui/icons-material/Logout';
import { GiCorn, GiGrain } from 'react-icons/gi';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { AuthContext } from '../../../context/auth';

const MenuItens = ({ selectedContent }) => {

  const theme = useTheme();

  const primaryColor = theme.palette.primary.main;

  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);
  const [contentPage, setContentPage] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {

    const content = searchParams.get('content');

    setContentPage(content || 'Boletas Soja');

    selectedContent(contentPage);

    try {

      if (!content?.includes('Editar')) document.querySelector(`[data-color="${content || 'Boletas Soja'}"]`).style.backgroundColor = 'rgba(206, 188, 188, 0.253)';
      if (content === 'Editar Boleta Soja') document.querySelector(`[data-color="${'Boletas Soja'}"]`).style.backgroundColor = 'rgba(206, 188, 188, 0.253)';
      if (content === 'Editar Quilometragem') document.querySelector(`[data-color="${'Quilometragem'}"]`).style.backgroundColor = 'rgba(206, 188, 188, 0.253)';
      if (content === 'Editar Boleta Milho') document.querySelector(`[data-color="${'Boletas Milho'}"]`).style.backgroundColor = 'rgba(206, 188, 188, 0.253)';

    } catch (error) {

      navigate('/notfoundpage');

    }

  }, [searchParams, contentPage, selectedContent, navigate]);

  const handleActive = (e, content) => {

    setContentPage(content);

    const elementos = document.querySelectorAll('[data-color]');
    elementos.forEach(element => element.style.backgroundColor = 'white')

    e.currentTarget.style.backgroundColor = 'rgba(206, 188, 188, 0.253)';

    setSearchParams({ 'content': content });

  }

  return (
    <>
      <List component='nav'>

        <ListItemButton data-color='Boletas Soja' onClick={(e) => handleActive(e, 'Boletas Soja')}>
          <ListItemIcon>
            <GiGrain style={{ ...styles.icon, color: primaryColor }} />
          </ListItemIcon>
          <ListItemText primary='Boletas Soja' />
        </ListItemButton>

        <ListItemButton data-color='Boletas Milho' onClick={(e) => handleActive(e, 'Boletas Milho')}>
          <ListItemIcon>
            <GiCorn style={{ ...styles.icon, color: primaryColor }} />
          </ListItemIcon>
          <ListItemText primary='Boletas Milho' />
        </ListItemButton>

        <ListItemButton data-color='Observações' onClick={(e) => handleActive(e, 'Observações')}>
          <ListItemIcon>
            <AssignmentIcon style={{ ...styles.icon, color: primaryColor }} />
          </ListItemIcon>
          <ListItemText primary='Observações' />
        </ListItemButton>

        <ListItemButton data-color='Quilometragem' onClick={(e) => handleActive(e, 'Quilometragem')}>
          <ListItemIcon>
            <SpeedIcon style={{ ...styles.icon, color: primaryColor }} />
          </ListItemIcon>
          <ListItemText primary='Quilometragem' />
        </ListItemButton>

        <Divider sx={{ my: 1 }} />

        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <Logout style={{ ...styles.icon, color: primaryColor }} />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItemButton>
      </List>

    </>
  )
}

const styles = {
  icon: {
    fontSize: 30,
  }
};

export default MenuItens;