import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Box, CircularProgress } from '@mui/material';

import { SingleSelect, Pagination, SplitButton, SearchBar, SlimButton, DataTable } from '../../../components';

import DownloadData from './DownloadData';

import useFetch from '../../../hooks/useFetch';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const BoletaSoja = () => {

  const [selectedSafra, setSelectedSafra] = useState((new Date().getFullYear()).toString());
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchedText, setSearchedText] = useState();

  const [, setSearchParams] = useSearchParams();

  const { data, loading } = useFetch(`${SERVER_URL}/soybean`, 'post', selectedSafra);

  const getSelectedSafra = (data) => setSelectedSafra(data);

  useEffect(() => {
    setSelectedPage(1);
  }, [searchedText]);

  const editBoleta = (boleta) => {
    setSearchParams({ 'content': 'Editar Boleta Soja', 'boletaSojaId': boleta['id_boleta'], 'safra': boleta['safra'] });

    sessionStorage.setItem('editar_boleta_soja', JSON.stringify(boleta));
  };

  let rows = [];
  const limitDataPerPage = 10;
  let pagination = 0;

  const boletasData = (() => {

    data?.forEach((item) => {

      const boletaLinha = {
        id_boleta: item['id_boleta'],
        safra: item['safra'],
        data: item['data'],
        amostra: item['amostra_graos'] || '-',
        equipe: item['equipe'],
        uf: item['uf'],
        municipio: item['municipio'],
        responsavel: item['responsavel'],
        editar: <SlimButton handleAction={() => editBoleta(item)} key={item + 'button'} />
      }

      rows.push(boletaLinha);
    });

    if (searchedText) {

      const searchTerm = searchedText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

      rows = rows.filter(item =>
        item['id_boleta']?.toString().includes(searchTerm)
        || item.data?.toString().includes(searchTerm)
        || item.safra?.toString().includes(searchTerm)
        || item.amostra?.toString().includes(searchTerm)
        || item.equipe.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.uf.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.municipio.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
        || item.responsavel.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
      );

    }

    pagination = Math.ceil(rows.length / limitDataPerPage);

    if (selectedPage > pagination && rows.length !== 0) setSelectedPage(1);

    const initialRowData = selectedPage * limitDataPerPage;
    const finalRowData = selectedPage * limitDataPerPage - limitDataPerPage;

    rows.sort((itemA, itemB) => Number(itemB['id_boleta']) - Number(itemA['id_boleta'])); //ordena por data mais recente

    rows = rows.filter((__, index) => (index) < initialRowData && (index) >= finalRowData);

    return rows;

  })();

  const typedText = (data) => setSearchedText(data?.toString());

  return (
    <>

      <Box width='100%' marginBottom='10px' style={{ display: 'flex' }}>
        <SearchBar typedText={typedText} />
        <SingleSelect getSelectedSafra={getSelectedSafra} />
      </Box>

      {loading && <CircularProgress style={styles.loadingAnimation} />}

      {(!loading && data?.length === 0) && <span style={{ fontStyle: 'italic' }}> Sem dados para a safra selecionada </span>}

      {(!loading && data?.length > 0) && (
        <DataTable
          columns={['Id Boleta', 'Safra', 'Data', 'Amostra', 'Equipe', 'UF', 'Município', 'Responsável']}
          items={['id_boleta', 'safra', 'data', 'amostra', 'equipe', 'uf', 'municipio', 'responsavel']}
          data={boletasData}
          editable
        />
      )}

      <Pagination selectedPage={selectedPage} pagination={pagination} setSelectedPage={setSelectedPage} />

      <Stack>
        <SplitButton safra={data} sheet='Soja' fileName='Boletas_Soja' downloadData={DownloadData} />
      </Stack>

    </>
  );
}

const styles = {
  loadingAnimation: {
    display: 'flex',
    width: '5%',
    height: '5%',
    alignSelf: 'center',
  },
}

export default BoletaSoja;