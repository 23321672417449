import { Grid, TextField, InputAdornment } from '@mui/material';

const GroupFields = ({ dataList, numInputs, label, name }) => {

  const createInput = () => {
    let inputs = [];

    for (let i = 1; i <= numInputs; i++) {

      inputs.push(

        <Grid item sx={{ width: '15%' }} key={i}>
          <TextField
            type='number'
            margin='normal'
            label={label + ' ' + i}
            name={name + i}
            defaultValue={dataList[name + i]}
            style={styles.input}
            onChange={(e) => dataList[e.target.name] = e.target.value}
            InputProps={{
              startAdornment: <InputAdornment position='start'></InputAdornment>,
            }}
          />

        </Grid>

      );
    }

    return inputs;
  }

  return (
    <>
      <Grid container item justifyContent='space-between'>

        {createInput()}

      </Grid>
    </>

  )
}

const styles = {
  input: {
    marginRight: '10px',
  }
}

export default GroupFields;