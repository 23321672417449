import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Grid, Container, IconButton, Divider, Toolbar, Box } from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { Copyright } from '../../components';

import BoletasSoja from './BoletasSoja';
import BoletasMilho from './BoletasMilho';
import Quilometragem from './Quilometragem';
import Observacoes from './Observacoes';
import EditarSoja2023 from './EditarBoleta/VersoesAnteriores/EditarSoja2023';
import EditarBoletaSoja from './EditarBoleta';
import EditarQuilometragem from './EditarQuilometragem';
import EditarBoletaMilho from './EditarBoletaMilho';
import EditarMilho2023 from './EditarBoletaMilho/VersoesAnteriores/EditarMilho2023'

import MenuItens from './MenuItens';
import AppBarHeader from './AppBar';

const drawerWidth = 320;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const HomeContent = () => {

  const [contentPage, setContentPage] = useState();

  const selectedContent = (data) => setContentPage(data);

  const [searchParams] = useSearchParams();

  const safraParam = searchParams.get('safra');

  const [open, setOpen] = useState(true);

  const toggleDrawer = () => setOpen(!open);

  return (

    <Box style={styles.container}>

      <AppBarHeader title={contentPage} open={open} toggleDrawer={toggleDrawer} />
      <Drawer variant='permanent' open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />

        <MenuItens selectedContent={selectedContent} />

      </Drawer>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Grid sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', width: 'fitContent' }}>

          <Container
            sx={{
              p: 2, 
              m: 2, 
              mt: 5, 
              display: 'flex', 
              flexDirection: 'column', 
              backgroundColor: 'white', 
              minWidth: '70vw', 
              borderRadius: '10px', 
              boxShadow: '0px 2px 4px rgba(19, 18, 18, 0.267)', 
              overflowX: 'scroll', 
              '::-webkit-scrollbar': {
                width: '5px',
                height: '8px',
                backgroundColor: '#F5F5F5',
                borderRadius: '10px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#873e23',
                borderRadius: '10px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#492417'
              }
            }}
          >

            {contentPage === 'Boletas Soja' && <BoletasSoja />}
            {contentPage === 'Boletas Milho' && <BoletasMilho  />}
            {contentPage === 'Observações' && <Observacoes />}
            {contentPage === 'Quilometragem' && <Quilometragem />}
            {/* Fora do Menu */}
            {(contentPage === 'Editar Boleta Soja' && safraParam === '2023') && <EditarSoja2023 />}
            {(contentPage === 'Editar Boleta Soja' && safraParam === '2024') && <EditarBoletaSoja />}

            {contentPage === 'Editar Quilometragem' && <EditarQuilometragem />}

            {(contentPage === 'Editar Boleta Milho' && safraParam === '2024') && <EditarBoletaMilho />}
            {(contentPage === 'Editar Boleta Milho' && safraParam === '2023') && <EditarMilho2023 />}
            
          </Container>

          <Copyright sx={{ pt: 4 }} />
        </Grid>
      </Box>
    </Box>

  );
};

const styles = {

  container: {
    width: '100%',
    display: 'flex',
  },
  
  icon: {
    fontSize: 30,
    color: '#873e23'
  }

};

export default HomeContent;