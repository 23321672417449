
import { Link } from 'react-router-dom';

const NotFound = () => {

  return (
    <div style={styles.container} className='not-found'>
      <div>
        <h2 style={styles.title}>Esta página não existe</h2>
        <p style={styles.subtitle}>Error 404</p>
        <Link style={styles.link} to='/home'>Voltar para a página inicial...</Link>
      </div>
    </div>
  );

};

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100vh',

    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    color: 'Brown',
    fontSize: 120,
  },

  subtitle: {
    color: 'Brown',
    fontSize: 50,
    display: 'flex',
  },
  
  link: {
    fontSize: 30,
  }
}

export default NotFound;