import { TextField, InputAdornment } from '@mui/material';

const TextFieldInput = ({ label, name, dataList, type, width }) => {
  return (
    <TextField
      sx={{ width: width }}
      type={type || 'text'}
      margin='normal'
      label={label}
      name={name}
      defaultValue={dataList[name]}
      style={styles.input}
      onChange={(e) => dataList[e.target.name] = e.target.value}
      InputProps={{
        startAdornment: <InputAdornment position='start'></InputAdornment>,
      }}
    />
  );
};

const styles = {
  input: {
    marginRight: '10px',
  }
}


export default TextFieldInput;