import { useState, useEffect } from 'react';
import { Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ typedText }) => {
  const [text, setText] = useState();

  useEffect(() => {
    typedText(text);
  }, [text, typedText]);

  return (
    <Paper
      component='form'
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '30%', mb: '20px', mr: '20px' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder='Pesquisar'
        inputProps={{ 'aria-label': 'Pesquisar' }}
        onChange={(e) => setText(e.target.value)}
      />
      <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
        <SearchIcon />
      </IconButton>

    </Paper>
  )
}

export default SearchBar;