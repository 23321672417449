import { createBrowserHistory } from 'history';
import { Box, Button} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ReturnButton = () => {

  const history = createBrowserHistory();

  return (
    <Box>
      <Button sx={{ textTransform: 'none', mb: 2 }} onClick={() => history.back()}> <KeyboardBackspaceIcon sx={{ mr: 0.5 }} /> Voltar </Button>
    </Box>
  )
}

export default ReturnButton;