import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { idRoleAdmin } from '../config';

export const AuthContext = createContext();

const AGROAUTH_URL = process.env.REACT_APP_AGROAUTH_URL;
const ID_SYSTEM = process.env.REACT_APP_ID_SYSTEM;

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);

  const login = async (login, password) => {
    setLoading(true);

    const options = {

      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({ login, password, idSystem: ID_SYSTEM }),
      credentials: 'include'

    };

    try {

      const response = await fetch(`${AGROAUTH_URL}/login`, options);

      const message = await response.json(); 
      
      setLoading(false);
      
      if (response.status === 403) return setMessageError('Usuário sem acesso ao sistema!');
      if (response.status === 401) return setMessageError('Verifique seu login e senha!');
      if (response.status !== 200) return setMessageError('Algum erro ocorreu. Contate o suporte!');
      
      const role = message.user.role;

      const isAdmin = role.id_role === idRoleAdmin;

      setIsAdmin(isAdmin);

      setMessageError(null);

      navigate('/home');

    } catch (err) {

      setMessageError(false);

      navigate('/login');
    }

    setLoading(false);

  }

  const logout = async () => {
    navigate('/login');

    setMessageError(false);

    const options = {
      method: 'GET',
      credentials: 'include',
    };

    await fetch(`${AGROAUTH_URL}/logout`, options);

  }

  return (
    <AuthContext.Provider value={{ login, logout, loading, messageError, isAdmin, setIsAdmin }}>
      {children}
    </AuthContext.Provider>
  )

}

export default AuthProvider;