import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

const DataTable = ({ columns, items, data, editable }) => {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell key={column}>{column}</TableCell>
          ))}
          {editable && <TableCell align='right' />}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <TableRow key={row[items[0]]}>
            {items.map(item => (
              <TableCell key={item}>{row[item]}</TableCell>
            ))}
            {editable && <TableCell align='right'>{row.editar}</TableCell>}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DataTable;